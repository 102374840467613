import { clear_console } from './Console';
import { simulator, update_state } from '../App';
import { useElfFile } from '../contexts/ElfFileContext';

// This can be removed later
let running = false;
export function stop_execution() {
  running = false;
}

function ControlBar() {
  function step() {
    simulator?.step();
    update_state();
  }

  async function multipleSteps() {
    if(!running) {
      running = true;
      while(running) {
        if (!simulator?.multiple_steps(50000 + getRandomInt(100))) {
          running = false;
        }
        update_state();
        await timeout(1);
      }
    }
    update_state();
  }

  function stop() {
    running = false;
  }

  async function reset() {
    stop();
    simulator?.reload_simulator();
    update_state();
    clear_console();
  }

  function timeout(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  function getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
  }

  const { setElfFile } = useElfFile();

  function returnToEditor() {
    setElfFile(undefined);
    stop();
    clear_console();
  }

  return (
      <div>
        <button onClick={step}>single step</button>
        <button onClick={multipleSteps}>run continuously</button>
        <button onClick={stop}>stop</button>
        <button className='danger' onClick={reset}>reset</button>
        <button className='right' onClick={returnToEditor}>edit program</button>
      </div>
  )
}

export default ControlBar;
