import './App.css'
import { ARMSimulator, WebOutput, WebExit } from "arm-simulator";
import { useEffect, useState } from 'react';
import Simulation from './pages/SimulationPage';
import CodeEditor from './pages/EditorPage';
import { print, print_error } from './components/Console';
import { stop_execution } from './components/ControlBar';
import { useElfFile } from './contexts/ElfFileContext';
import { loader } from '@monaco-editor/react';

loader.config({ paths: { vs: '/monaco-editor/min/vs' } });


export let simulator: ARMSimulator | null = null;

export function update_state() {
  window.postMessage({ type: 'state_has_changed'})
}

export function update_disassembly() {
  window.postMessage({ type: 'disassembly_has_changed'})
}

function App() {
  const { elfFile, setElfFile } = useElfFile();
  const [simLoaded, setSimLoaded] = useState(false);

  function initSimulator(file_content: Uint8Array) {
    let webOutput = new WebOutput(print, print_error);
    let webExit = new WebExit(print, stop_execution);
    
    try {
      simulator = new ARMSimulator(file_content, webOutput, webExit);
      update_state();
      update_disassembly();
      setSimLoaded(true);
    } catch (error) {
      setElfFile(undefined);
      alert(error);
    }
  }

  useEffect(() => {
    if (elfFile) {
      elfFile.arrayBuffer().then(file_content => {
        initSimulator(new Uint8Array(file_content));
      });
    }
    else {
      setSimLoaded(false);
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elfFile]);


  return (
    <div>
      <ul className="header">
        <li className="header title">
          <span className="title-first-letters">ARM</span>Simulator
        </li>
        <li className="header">
          <a href="https://www.uibk.ac.at/informatik/">
            <img className="uni-logo" src="/images/uibk-logo.svg" alt='UIBK Logo'/>
          </a>
        </li>
      </ul>
      
      <div style={!simLoaded ? {} : {display: "none"}}>
        <CodeEditor/>
      </div>


      {simLoaded ? <Simulation/> : null}    
      </div>
  );
}

export default App;
