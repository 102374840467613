import React, { createContext, useContext, useState, ReactNode } from 'react';

interface MemoryAddressContextType {
  baseAddress: number;
  setBaseAddress: React.Dispatch<React.SetStateAction<number>>;
}

const MemoryAddressContext = createContext<MemoryAddressContextType | undefined>(undefined);

export function MemoryAddressProvider({ children }: {children: ReactNode} ) {
  const [baseAddress, setBaseAddress] = useState<number>(0);

  return (
    <MemoryAddressContext.Provider value={{ baseAddress, setBaseAddress }}>
      {children}
    </MemoryAddressContext.Provider>
  );
}

export function useMemoryAddress() {
  const context = useContext(MemoryAddressContext);
  if (!context) {
    throw new Error('useMemoryAddress must be used within a MemoryAddressProvider');
  }
  return context;
}