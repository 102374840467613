import { useState, useEffect } from 'react';
import { simulator, update_state } from '../App';
import HexInput from './HexInput';
import BitInput from './BitInput';
import React from 'react';
import { RiSdCardMiniFill } from "react-icons/ri";
import { useMemoryAddress } from '../contexts/MemoryAddressContext';

const REGISTER_NAMES = [
  "R0", "R1", "R2", "R3", "R4", "R5", "R6", "R7",
  "R8", "R9", "R10", "R11", "R12", "SP", "LR", "PC"
];

function State() {
  const [regVals, setRegVals] = useState(getRegisters());
  const [flagVals, setFlagVals] = useState(getFlags());

  const { setBaseAddress } = useMemoryAddress();

  function getRegisters() {
    return simulator?.get_registers() ?? new Int32Array(16).fill(0);
  }
  
  function getFlags() {
    let flags: boolean[] = [];
    for(let i = 0; i < 4; i++) {
      flags[i] = simulator?.get_flag(i) ?? false;
    }
    return flags;
  }

  function updateRegister(index: number) {
    return (value: number) => {
      simulator?.set_register(index, value);
      update_state();
    };
  }

  function updateFlag(index: number) {
    return (value: boolean) => {
      simulator?.set_flag(index, value);
      update_state();
    };
  }

  function regToMem(index: number) {
    return () => {
      setBaseAddress(regVals[index] >>> 0);
    };
  }

  useEffect(() => {
    function handleMessage(event: MessageEvent) {
      if (event.data.type === 'state_has_changed') {
        setRegVals(getRegisters());
        setFlagVals(getFlags());
      }
    }
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <div>
      &nbsp;&nbsp;
      <div style={{display: "inline-block", textAlign: "right"}}>
        {REGISTER_NAMES.slice(0, 8).map((n, i) => (
          <span key={n} style={{fontSize: "20px", marginLeft: "15px"}}>
            {n}: <HexInput input={regVals[i]} onChange={updateRegister(i)} 
                    glow={true} style={{marginBottom: "8px", textAlign: "center"}}
                 />
                 <RiSdCardMiniFill className="icon-button" style={{marginLeft: "3px", position: "relative", top: "3px"}}
                  onClick={regToMem(i)}
                 />
            <br/>
          </span>
        ))}
      </div>
      <div style={{display: "inline-block", textAlign: "right"}}>
        {REGISTER_NAMES.slice(8, 16).map((n, i) => (
          <span key={n} style={{fontSize: "20px", marginLeft: "15px"}}>
            {n}: <HexInput input={regVals[i+8]} onChange={updateRegister(i+8)} 
                    glow={true} style={{marginBottom: "8px", textAlign: "center"}}
                 />
                 <RiSdCardMiniFill className="icon-button" style={{marginLeft: "3px", position: "relative", top: "3px"}}
                  onClick={regToMem(i+8)}
                 />
            <br/>
          </span>
        ))}
      </div>
      <div style={{marginTop: "15px"}}>
        <div>
          <span style={{fontSize: "20px", paddingLeft: "25px"}}>
            N: <BitInput input={flagVals[0]} onChange={updateFlag(0)}/>
          </span>
          <span style={{fontSize: "20px", paddingLeft: "40px"}}>
            Z: <BitInput input={flagVals[1]} onChange={updateFlag(1)}/>
          </span>
        </div>
        <div>
          <span style={{fontSize: "20px", paddingLeft: "25px"}}>
            C: <BitInput input={flagVals[2]} onChange={updateFlag(2)}/>
          </span>          
          <span style={{fontSize: "20px", paddingLeft: "40px"}}>
            V: <BitInput input={flagVals[3]} onChange={updateFlag(3)}/>
          </span>
        </div>
      </div>
    </div>
  )
}

export default State;
